



















































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Action, State } from 'vuex-class'
import { SEARCH_TALENT_BY_NAME } from '@/store/employee/actions.type'
import { SimpleServerResponse, TableFields, TalentProfile } from '@/store/types'
import { Prop, Watch } from 'vue-property-decorator'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import { BTable } from 'bootstrap-vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { parseDateToOutputDate } from '@/common/globals'

@Component({
  components: { LoadingSpinner, ClickableIcon }
})
/*
@group HSZG
This component is used in the creation of a KODE evaluation session (employee).
It shows the modal dialog in which the employee can add the talents for the session.
*/
export default class KodeEvaluationSessionParticipantsModal extends Vue {
  $refs!: {
    talentSearchResultTable: BTable;
  }

  @State('currentScreenWidth') screenWidth: number

  @Action(SEARCH_TALENT_BY_NAME)
  public searchTalentByName: (params: string) => Promise<SimpleServerResponse>

  // array of talent profiles
  @Prop()
  profiles: TalentProfile[]

  // confirm button text in the modal footer (input is i18n path)
  @Prop()
  confirmCaption: string

  // cancel button text in the modal footer (input is i18n path)
  @Prop()
  cancelCaption: string

  @Watch('profiles', { immediate: true })
  onProfilesChanged (newValue: TalentProfile[]) {
    this.allSelectedProfiles = newValue
  }

  private searchQuery: string | null = null
  private searchResult: TalentProfile[] = []
  private allSelectedProfiles: TalentProfile[] = []
  private selectedInSearchResultTable: TalentProfile[] = []
  private isLoading = false
  private loaded = false

  get searchResultFields (): TableFields[] {
    const selectionOverview = [...this.selectionOverviewFields]
    selectionOverview.push(
      {
        key: 'email',
        label: this.$i18n.t('personal-information.email').toString(),
        sortable: true
      }
    )
    return selectionOverview
  }

  get selectionOverviewFields (): TableFields[] {
    return [
      {
        key: 'profile_exists',
        label: this.$i18n.t('kode-evaluation.kode-profile-exists').toString(),
        sortable: false
      },
      {
        key: 'details_send',
        label: this.$i18n.t('kode-evaluation.details-sent').toString(),
        sortable: true
      },
      {
        key: 'firstname',
        label: this.$i18n.t('personal-information.firstName').toString(),
        sortable: true
      },
      {
        key: 'lastname',
        label: this.$i18n.t('personal-information.lastName').toString(),
        sortable: true
      }
    ]
  }

  search (event: Event): void {
    event.preventDefault()
    this.isLoading = true
    this.loaded = false
    if (this.searchQuery === null) {
      this.searchQuery = ''
    }
    this.searchTalentByName(this.searchQuery).then(data => {
      this.searchResult = data.content
      this.selectedInSearchResultTable = []
      this.allSelectedProfiles.forEach(selectedProfile => {
        const index = this.findIndex(selectedProfile, this.searchResult)
        if (index !== -1) {
          this.searchResult[index].selected = true
          if (this.$refs.talentSearchResultTable) this.$refs.talentSearchResultTable.selectRow(index)
          const tbody = document.getElementById('talent-search-result-table')?.getElementsByTagName('tbody')[0]
          const trList = tbody?.getElementsByTagName('tr')
          if (trList?.length === 0 && this.searchResult.length !== 0) {
            setTimeout(() => {
              this.$refs.talentSearchResultTable.selectRow(index)
            }, 1000)
          } else {
            this.$refs.talentSearchResultTable.selectRow(index)
          }
        }
      })
    }).finally(() => {
      this.loaded = true
      this.isLoading = false
    })
  }

  selectTalent (newTalentSelection: TalentProfile[], onlyDelete: boolean): void {
    // add talents that are new
    newTalentSelection.forEach(talent => {
      const index = this.findIndex(talent, this.allSelectedProfiles)
      if (index === -1) {
        talent.selected = true
        this.allSelectedProfiles.push({ ...talent })
      }
    })

    if (onlyDelete) {
      newTalentSelection.forEach(toBeDeleted => {
        this.allSelectedProfiles.splice(this.findIndex(toBeDeleted, this.allSelectedProfiles), 1)
        this.selectedInSearchResultTable.splice(this.findIndex(toBeDeleted, this.selectedInSearchResultTable), 1)
        this.searchResult[this.findIndex(toBeDeleted, this.searchResult)].selected = false
        const tdList = document.getElementsByTagName('td')
        for (let i = 0; i < tdList.length; i++) {
          const td = tdList.item(i)
          if (td?.innerText === toBeDeleted.email) {
            return td.parentElement?.classList.remove('b-table-row-selected', 'table-active')
          }
        }
      })
    } else {
      // delete talents from list that got unselected
      this.selectedInSearchResultTable.forEach(talent => {
        const index = newTalentSelection.findIndex(newTalent => {
          return newTalent.profileId === talent.profileId
        })

        if (index === -1) {
          talent.selected = false
          this.allSelectedProfiles.splice(this.findIndex(talent, this.allSelectedProfiles), 1)
          const indexInSearchResults = this.findIndex(talent, this.searchResult)
          if (indexInSearchResults !== -1) {
            this.searchResult[this.findIndex(talent, this.searchResult)].selected = false
            this.$refs.talentSearchResultTable.unselectRow(indexInSearchResults)
          }
        }
      })
    }

    this.selectedInSearchResultTable = newTalentSelection
  }

  findIndex (talent: TalentProfile, talentList: TalentProfile[]): number {
    return talentList.findIndex(comparable => {
      return comparable.profileId === talent.profileId
    })
  }

  deleteInputState (emitResults: boolean): void {
    this.searchQuery = ''
    this.searchResult = []
    this.selectedInSearchResultTable = []

    if (emitResults) {
      this.$emit('new-participants', this.allSelectedProfiles)
    }
  }

  parseDateToOutputDate (date: Date): string | undefined {
    return parseDateToOutputDate(date, this.$i18n.locale)
  }
}
