












































































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { SimpleServerResponse, TableFields, TalentProfile } from '@/store/types'
import { KodeEvaluationSession, TalentPdfAllowance } from '@/store/kode/types'
import { Action, State } from 'vuex-class'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import KodeEvaluationSessionParticipantsModal from '@/components/hszg/KodeEvaluationSessionParticipantsModal.vue'
import {
  GET_KODE_EVALUATION_SESSION,
  GET_KODE_EVALUATION_VISIBILITY,
  SAVE_KODE_EVALUATION_SESSION, SAVE_KODE_EVALUATION_VISIBILITY
} from '@/store/kode/actions'
@Component({
  components: { ConfirmationModal, ClickableIcon, KodeEvaluationSessionParticipantsModal }
})
export default class KodeEvaluationSessionEditCreate extends Vue {
  @Action(SAVE_KODE_EVALUATION_SESSION)
  saveSessionCall: (session: KodeEvaluationSession) => Promise<string>

  @Action(GET_KODE_EVALUATION_SESSION)
  getKodeEvaluationSession: (id: string) => Promise<KodeEvaluationSession>

  @Action(GET_KODE_EVALUATION_VISIBILITY)
  getKodeEvaluationVisibility: (talentIdList: string[]) => Promise<Array<TalentPdfAllowance>>

  @Action(SAVE_KODE_EVALUATION_VISIBILITY)
  saveKodeEvaluationVisibility: (visibilityMatrix: TalentPdfAllowance[]) => Promise<SimpleServerResponse>

  @State('currentScreenWidth') screenWidth: number

  @Prop()
  createMode: boolean

  private id = ''
  private nameModel: string | null = null
  private participantsModel: TalentPdfAllowance[] = []
  private orderBy = 'lastName'
  private uncheckedValue = false
  private bookmarkedForDelete: TalentPdfAllowance = {
    profileStudent: undefined,
    firstName: undefined,
    lastName: undefined,
    visibleCompetenceAtlas: false,
    visibleCompetenceProfile: false,
    visibleDealingsWithOtherPeople: false,
    visibleInterpretation: false,
    visibleLearningHabits: false,
    visibleTeamCompetences: false,
    visibleTimeManagement: false
  }

  private feedbackHeadline = ''
  private responseState = false

  // indeterminateMap = new Map<string, boolean>()
  checkedObject: TalentPdfAllowance = {
    visibleCompetenceAtlas: false,
    visibleCompetenceProfile: false,
    visibleDealingsWithOtherPeople: false,
    visibleInterpretation: false,
    visibleLearningHabits: false,
    visibleTeamCompetences: false,
    visibleTimeManagement: false
  }

  get fields (): TableFields[] {
    return [
      { key: 'participants', label: this.$i18n.t('kode-evaluation.participants').toString() },
      { key: 'visibleCompetenceProfile', label: this.$i18n.t('kode-metering.competence-profile').toString() },
      { key: 'visibleInterpretation', label: this.$i18n.t('kode-metering.interpretation').toString() },
      { key: 'visibleCompetenceAtlas', label: this.$i18n.t('kode-metering.competence-atlas').toString() },
      { key: 'visibleDealingsWithOtherPeople', label: this.$i18n.t('kode-metering.dealings-with-other-people').toString() },
      { key: 'visibleLearningHabits', label: this.$i18n.t('kode-metering.learning-habits').toString() },
      { key: 'visibleTimeManagement', label: this.$i18n.t('kode-metering.time-management').toString() },
      { key: 'visibleTeamCompetences', label: this.$i18n.t('kode-metering.team-competences').toString() }
    ]
  }

  get participantsAsTalentProfileList (): Array<TalentProfile> {
    return this.participantsModel.map(participant => {
      return {
        firstname: participant.firstName ? participant.firstName : '',
        lastname: participant.lastName ? participant.lastName : '',
        profileId: participant.profileStudent ? participant.profileStudent : '',
        email: '',
        selected: true,
        kodeStatus: ''
      }
    })
  }

  get indeterminateMap (): Map<string, boolean> {
    const indeterminateMap = new Map<string, boolean>()
    const keys = ['visibleCompetenceAtlas', 'visibleCompetenceProfile', 'visibleDealingsWithOtherPeople', 'visibleInterpretation', 'visibleLearningHabits', 'visibleTeamCompetences']

    keys.forEach(key => {
      const allValues = this.participantsModel.map((participant: TalentPdfAllowance) => {
        return (participant[key as keyof TalentPdfAllowance])
      })

      const containsTrueValue = allValues.includes(true)
      const containsFalseValue = allValues.includes(this.uncheckedValue)
      indeterminateMap.set(key, containsTrueValue && containsFalseValue)

      this.setAllowanceValue(this.checkedObject, key, (containsTrueValue && containsFalseValue) || containsTrueValue)
    })

    return indeterminateMap
  }

  public isStatusFinish (item: TalentPdfAllowance): boolean {
    return item && item.kodeStatus === 'FINISH'
  }

  public showDeleteConfirmationModal (talent: TalentPdfAllowance): void {
    this.bookmarkedForDelete = talent
    this.$bvModal.show('confirmation-modal-delete-talent')
  }

  public addParticipants (talents: TalentProfile[]): void {
    // find still relevant talents
    const stillRelevant = talents.map(talent => {
      const index = this.participantsModel.findIndex(oldParticipant => {
        return oldParticipant.profileStudent === talent.profileId
      })

      if (index !== -1) {
        return this.participantsModel[index]
      }
    })

    this.participantsModel = []

    if (stillRelevant.length > 0 && typeof stillRelevant[0] !== 'undefined') {
      this.participantsModel.concat(stillRelevant as TalentPdfAllowance[])
    }

    // find new talents
    const newIds = talents.map(talent => {
      if (!this.participantsModel.some(oldParticipant => {
        return oldParticipant.profileStudent === talent.profileId
      })) {
        return talent.profileId
      }
    })

    if (newIds.length > 0 && typeof newIds[0] !== 'undefined') {
      this.getKodeEvaluationVisibility(newIds as string[]).then(data => {
        this.participantsModel.push(...data)
        this.saveSession()
      })
    }
  }

  public deleteParticipant (): void {
    const index = this.participantsModel.findIndex(talent => {
      return talent.profileStudent === this.bookmarkedForDelete?.profileStudent
    })

    if (index !== -1) {
      this.participantsModel.splice(index, 1)
      this.$root.$emit('bv::refresh::table', 'pdf-enable-table')
    }

    this.saveSession()
  }

  saveChanges (): void {
    this.$root.$emit('load')
    this.saveKodeEvaluationVisibility(this.participantsModel).then(() => {
      this.$root.$emit('alert', this.$i18n.t('info.changed-success', { what: this.$i18n.t('data') }))
    }).catch(error => {
      this.$root.$emit('alert', error.message)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  updateAllowanceForAll (event: boolean, key: string): void {
    const value = !(event === this.uncheckedValue)
    this.participantsModel.forEach(participant => {
      this.setAllowanceValue(participant, key, value)
    })
  }

  updateAllowanceForOne (event: boolean, key: string, talent: TalentPdfAllowance): void {
    this.setAllowanceValue(talent, key, event)
  }

  setAllowanceValue (object: TalentPdfAllowance, key: string, value: boolean): void {
    switch (key) {
      case 'visibleCompetenceAtlas': {
        object.visibleCompetenceAtlas = value
        break
      }
      case 'visibleCompetenceProfile': {
        object.visibleCompetenceProfile = value
        break
      }
      case 'visibleDealingsWithOtherPeople': {
        object.visibleDealingsWithOtherPeople = value
        break
      }
      case 'visibleInterpretation': {
        object.visibleInterpretation = value
        break
      }
      case 'visibleLearningHabits': {
        object.visibleLearningHabits = value
        break
      }
      case 'visibleTeamCompetences': {
        object.visibleTeamCompetences = value
        break
      }
      case 'visibleTimeManagement': {
        object.visibleTimeManagement = value
        break
      }
      default: {
        break
      }
    }
  }

  saveSession (): void {
    const sessionToSave: KodeEvaluationSession = { name: '', participants: [] }
    if (this.nameModel !== null) {
      if (this.id !== '') {
        sessionToSave.id = this.id
      }

      sessionToSave.name = this.nameModel
      sessionToSave.participants = this.participantsModel.map(talent => {
        return talent.profileStudent ? talent.profileStudent : ''
      })

      this.$root.$emit('load')
      this.saveSessionCall(sessionToSave).then(id => {
        this.id = id
        this.feedbackHeadline = this.$i18n.t('kode-evaluation.save.SUCCESS').toString()
        this.responseState = true
      }).catch(() => {
        this.feedbackHeadline = this.$i18n.t('kode-evaluation.save.FAILED').toString()
        this.responseState = false
      }).finally(() => {
        this.$root.$emit('end-load')
        this.$root.$emit('alert', this.feedbackHeadline, '', !this.responseState)
      })
    }
  }

  gotoKodeScore (): void {
    this.$router.push({ name: 'hszg-kode-evaluation-single-person' })
  }

  mounted (): void {
    this.id = this.$route.params.id
    if (this.id) {
      this.$root.$emit('load')
      this.getKodeEvaluationSession(this.id).then(session => {
        this.nameModel = session.name
        if (session.participants && session.participants.length > 0) {
          this.getKodeEvaluationVisibility(session.participants).then(data => {
            this.participantsModel = data
          }).catch(() => {
            this.participantsModel = []
          })
        }
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }
  }

  alignTableHeadings (): void {
    const table = document.getElementById('pdf-enable-table')
    if (table !== null) {
      const tableWidth = table.clientWidth
      const headings = table.getElementsByTagName('th')
      const firstHeading = headings.item(0)
      if (firstHeading !== null) {
        const firstHeadingWidth = firstHeading.clientWidth
        const headingWidth = (tableWidth - firstHeadingWidth) / (this.fields.length - 1)
        for (let i = 1; i < headings.length; i++) {
          const item = headings.item(i)
          if (item !== null) {
            item.style.width = headingWidth.toString() + 'px'
            const tangent = headingWidth / item.clientHeight
            const p = item.getElementsByTagName('p').item(0)
            if (p !== null) {
              p.style.transform = 'rotate(' + (Math.PI + Math.atan(tangent)) + 'RAD)'
            }
          }
        }
      }
    }
  }

  updated (): void {
    this.alignTableHeadings()
  }
}
